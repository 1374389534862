<template>
  <div class="mt-4 container order" style="min-height: 65vh;">
    <h2 class="text-start mb-3 order__title">您欲購買的課程</h2>
    <div class="row">
      <div class="col-12">
        <div class="card border-0 text-start order__card mb-4 position-relative">
          <div class="card-body p-4">
            <h5 class="card-title mb-4 order__coursetitle">課程名稱：{{ course.courseName }}</h5>
            <div class="d-flex justify-content-between align-items-end">
              <div class="order__card__left">
                <!-- <img :src="course.coursePrevImgUrl" alt="" v-if="course.coursePrevImgUrl !== 'NotSet' || course.coursePrevImgUrl !== ''"> -->
                <img src="https://imgur.com/uQFCjm6.png" alt="">
              </div>
              <div class="order__card__right">
                <h6 class="card-subtitle mb-2 text-muted">課程售價</h6>
                <p class="card-text mb-2 order__price">$ {{ course.price }}</p>
              </div>
            </div>
          </div>
          <div class="course__channel" v-if="hasSalesChannel">
            <div class="course__channel__txt">
              {{ course.salesChannel.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isB2C">
      <div class="col-12">
        <div class="card border-0 text-start order__card mb-4">
          <div class="card-body p-4">
            <h5 class="card-title mb-4 order__coursetitle">付款方式</h5>
            <div class="d-flex mb-2">
              <img src="../assets/images/visa.png" alt="visa" class="order__cardLogo me-2">
              <img src="../assets/images/mastercard.svg" alt="mastercard" class="order__cardLogo me-2">
              <img src="../assets/images/jcb.png" alt="jcb" class="order__cardLogo">
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <select class="form-select" aria-label="Default select example">
                <option value="1" selected>信用卡</option>
              </select>
            </div>
            <h5 class="card-title mb-4 order__coursetitle mt-4">發票設定</h5>
            <span class="d-block mb-2 text-muted" style="line-height: 1.3rem;">以下資訊只用於開立發票，並不會在其他頁面顯示，發票一經開立後不可更改。</span>
            <div class="mt-4">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form class="" @submit.prevent="handleSubmit(checkOut)">
                  <div class="row">
                    <div class="mb-3 col-12 col-md-6">
                      <label for="exampleFormControlInput1" class="form-label"><p class="text-danger d-inline">*</p>姓名
                        <small style="font-size: 9px;" class="text-danger">（中文字）</small>
                      </label>
                      <ValidationProvider rules='required|orderer' v-slot='{ errors , classes }'>
                        <div :class="classes">
                          <input type="text" class="form-control" placeholder="您的姓名" v-model="name">
                          <span class="text-danger mt-1">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="mb-3 col-12 col-md-6">
                      <label for="exampleFormControlInput1" class="form-label"><p class="text-danger d-inline">*</p>電子信箱</label>
                      <ValidationProvider rules='required|email' v-slot='{ errors , classes }'>
                        <div :class="classes">
                          <input type="email" class="form-control" placeholder="您的信箱" v-model="email">
                          <span class="text-danger mt-1">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3 col-12 col-md-6">
                      <label for="exampleFormControlInput1" class="form-label"><p class="text-danger d-inline">*</p>發票類型
                        <small style="font-size: 9px;" class="text-danger"></small>
                      </label>
                      <select class="form-select">
                        <option value="1" selected>電子發票</option>
                      </select>
                    </div>
                    <div class="mb-3 col-12 col-md-6">
                      <label for="exampleFormControlInput1" class="form-label"><p class="text-danger d-inline">*</p>發票載具
                        <small style="font-size: 9px;" class="text-danger"></small>
                      </label>
                      <select class="form-select" v-model="invoiceViechle">
                        <option value="1" selected>儲存在會員載具中</option>
                        <option value="3">手機條碼</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3 col-12 col-md-6" v-if="invoiceViechle == 3">
                      <label for="exampleFormControlInput1" class="form-label"><p class="text-danger d-inline">*</p>手機條碼</label>
                      <ValidationProvider rules='required|barcode' v-slot='{ errors , classes }'>
                        <div :class="classes">
                          <input type="text" class="form-control" placeholder="您的手機條碼" v-model="barcode">
                          <span class="text-danger mt-1">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <p class="mb-4 order__notice"><span><i class="bi bi-info-circle me-2"></i></span>下一步將連至綠界金流服務進行付款，你所有的交易資訊皆獲得安全保護。</p>
                  <button class="btn btn-main btn-checkout" type="submit" :disabled="disabled">前往付款</button>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isB2B">
      <div class="col-12">
        <div class="card border-0 text-start order__card mb-4">
          <div class="card-body p-4">
            <h5 v-if="isB2B && isB2C" class="card-title mb-4 order__coursetitle">
              或聯繫下列業務，購買此課程 >>
            </h5>
            <h5 class="card-title mb-4 order__coursetitle" v-else>
              欲購買此課程，請聯繫下列業務 >>
            </h5>

            <div>
              <div v-html="decodeEntity(decodeEntity(course.b2BContact))"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Meta :title ="title" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Order',
  data() {
    return {
      courseId: '',
      course: '',
      invoiceViechle: 1,
      name: '',
      email: '',
      barcode: '',
      actionUrl: '',
      merchantTradeNo: '',
      checkMacValue: '',
      MerchantID: '2000214',
      MerchantTradeDate: new Date(),
      PaymentType: 'aio',
      TotalAmount: 0,
      TradeDesc: '',
      ItemName: '',
      ReturnURL: 'https://ecpay.mypeoplevol.com/Checkout/ECPayCallback',
      ChoosePayment: 'Credit',
      EncryptType: 1,
      ClientBackURL: 'https://www.metalearning.com.tw/order/D2022060109250604722',
      disabled: false,
      isB2B: false,
      isB2C: false,
      title: '購買課程 - 元學堂',
      hasSalesChannel: false,
    };
  },
  metaInfo() {
    return {
      title: `${this.course.courseName} - 購買課程`,
      meta: [
        { name: 'description', content: `${this.course.courseName} - 購買課程` },
        {
          property: 'og:title',
          content: `${this.course.courseName} - 購買課程`,
        },
        {
          property: 'og:description',
          content: `${this.course.courseName} - 購買課程` ,
        },
      ]
    }
  },
  methods: {
    getCourse() {
      let api;
      if(this.$route.query.salesChannel !== undefined) {
        api = `${this.$API_PATH}/Course?salesChannel=${this.$route.query.salesChannel}&CourseId=${this.courseId}`;
      } else {
        api = `${this.$API_PATH}/Course?CourseId=${this.courseId}`;
      }
      this.$http.get(api, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        if (res.data.data) {
          this.course = res.data.data;
          this.$log.debug(this.course);
          if(this.course.purchased) {
            this.$router.push(`/course_main/${this.courseId}`);
          }
          this.isB2B = this.course.isB2B;
          this.isB2C = this.course.isB2C;
          const today = new Date();
          if(res.data.data.salesChannel !== null 
            && today.getTime() > new Date(res.data.data.salesChannel.startTime).getTime()
            && today.getTime() < new Date(res.data.data.salesChannel.endTime).getTime()) {
            this.hasSalesChannel = true;
          } else {
            this.hasSalesChannel = false;
          }
            // console.log(this.decodeEntity(this.decodeEntity(this.course.b2BContact)));
          }
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    checkOut() {
      const vm = this;
      this.updateLoading(true);
      if (this.name == '') {
        return;
      }
      vm.disabled = true;
      const data = {
        courseId: this.courseId,
        invoiceName: this.name,
        invoiceEmail: this.email,
        invoiceCarruerType: this.invoiceViechle,
        invoiceCarruerNum: this.invoiceViechle == 3 ? this.barcode : '0',
      };
      this.$http.post(`${this.$API_PATH}/Checkout/Course/NewOrder`, data, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        // console.log(res.data);
        this.updateLoading(false);
        this.checkMacValue = res.data.checkMacValue;
        this.merchantTradeNo = res.data.merchantTradeNo;
        // 可能要帶入promotion code
        window.location.replace(`https://www.metalearning.com.tw/payment.html?MerchantTradeNo=${this.merchantTradeNo}&CheckMacValue=${this.checkMacValue}`);
        this.updateLoading(false);
        vm.disabled = false;
      }).catch((err) => {
        this.updateLoading(false);
        vm.disabled = false;
        this.$router.push('/error');
        this.$log.debug(err);
      });
    },
    replaceQuote(str) {
      return str.replace(/"/g, '');
    },
    decodeEntity(inputStr) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = inputStr;
      return textarea.value;
    },
    ...mapActions(['updateLoading', 'getUserAuth', 'getUserFromLocal', 'getUserAuthFromLocal', 'checkExpAuth']),
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['userAuth', 'userState']),
  },
  created() {
    this.courseId = this.$route.params.id;
    this.getUserFromLocal();
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    if (this.userAuth.length !== 0 && this.userState.length !== 0) {
      this.getCourse();
    } else {
      this.$router.push('/login');
    }
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }
  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }
  .card {
    &-body {
      td {
        line-height: 1.5rem;
      }
      a {
        color: black;
        text-decoration: none;
      }
    }
  }
</style>